@font-face {
    font-family: 'Americane-Bold';
    src: url("./assets/fonts/AmericaneCondensed/Americane-bold.otf");
  }
  
  @font-face {
    font-family: 'Roboto-Regular';
    src: url("./assets/fonts/Roboto/RobotoCondensed-Regular.ttf");
  }

  @font-face {
    font-family: 'Goodlife';
    src: url("./assets/fonts/GoodlifeCondensed/Goodlife\ Sans\ Condensed.otf");
  }

body {
    background-color: #fff;
    background-size: cover;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-top: auto;
    position: absolute;
    margin: auto;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#__next {
    height: 100%;
}
